import React from 'react';
import SimpleBar from 'simplebar-react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, Figure, Image } from 'react-bootstrap';
import { CDN_ROOT } from '../../../_helpers/constants';

// Utilities
import utils from '../../../_helpers/utils';
import MetaDecorator from '../../../_helpers/MetaDecorator';

// Styles
import './Contacts.scss';

export default class Contacts extends React.Component {
  constructor(props) {
    super(props);

    // Criminal Mission Contacts
    this.criminalTree = {
      r1: [
        {
          sAPBDB: 'BloodRoses'
        },
        {
          sTitle: 'Britney Bloodrose',
          sAPBDB: 'Waterfront_C3'
        },
        {
          sTitle: 'Byron Bloodrose',
          sAPBDB: 'Waterfront_C5'
        },
        {
          sTitle: 'Seung Bloodrose',
          sAPBDB: 'Financial_C9'
        },
        {
          sTitle: 'Tyron Sennet',
          sAPBDB: 'Waterfront_C7'
        },
        {
          sTitle: 'Michael Simeone',
          sAPBDB: 'Waterfront_C9'
        },
        {
          sTitle: 'Tiptoe',
          sAPBDB: 'Financial_C11'
        },
        {
          sTitle: 'Gumball',
          sAPBDB: 'Financial_C12'
        }
      ],
      r2: [
        {
          sTitle: 'Double-B',
          sAPBDB: 'Financial_C1'
        },
        {
          sTitle: 'Strega Bloodrose',
          sAPBDB: 'Financial_C4'
        },
        {
          sTitle: 'Suji',
          sAPBDB: 'Financial_C5'
        },
        {
          sTitle: 'Lilith Bloodrose',
          sAPBDB: 'Waterfront_C6'
        },
        {
          sTitle: 'Jeung Bloodrose',
          sAPBDB: 'Waterfront_C8'
        }
      ],
      r3: [
        {
          sTitle: 'Veronika Lee',
          sAPBDB: 'Financial_C2'
        },
        {
          sTitle: 'Shift',
          sAPBDB: 'Financial_C6'
        },
        {
          sTitle: 'Harmon Benjamin',
          sAPBDB: 'Waterfront_C2'
        },
        {
          sTitle: 'Bonita Benjamin',
          sAPBDB: 'Financial_C3'
        },
        {
          sTitle: 'Arlon Benjamin',
          sAPBDB: 'Financial_C7'
        },
        {
          sTitle: 'Birth',
          sAPBDB: 'Waterfront_C11'
        }
      ],
      r4: [
        {
          sAPBDB: 'Gkings'
        },
        {
          sTitle: 'Terri Quan',
          sAPBDB: 'Waterfront_C1'
        },
        {
          sTitle: 'Chiro',
          sAPBDB: 'Waterfront_C10'
        },
        {
          sTitle: 'Javez',
          sAPBDB: 'Financial_C10'
        },
        {
          sTitle: 'Grayson Fell',
          sAPBDB: 'Financial_C8'
        }
      ],
    }

    // Enforcer Mission Contacts
    this.enforcerTree = {
      r1: [
        {
          sAPBDB: 'PrentissTigers'
        },
        {
          sTitle: 'Chung-hee',
          sAPBDB: 'Waterfront_E2'
        },
        {
          sTitle: 'Stu Phoenix',
          sAPBDB: 'Waterfront_E4'
        },
        {
          sTitle: 'Darryl Kent',
          sAPBDB: 'Financial_E2'
        },
        {
          sTitle: 'Chiza',
          sAPBDB: 'Waterfront_E6'
        },
        {
          sTitle: 'Orlenz\' Moretti',
          sAPBDB: 'Waterfront_E8'
        },
        {
          sTitle: 'Devil Dog',
          sAPBDB: 'Financial_E11'
        },
        {
          sTitle: 'Aletta Cadagan',
          sAPBDB: 'Financial_E12'
        }
      ],
      r2: [
        {
          sTitle: 'Wilson LeBoyce',
          sAPBDB: 'Financial_E8'
        },
        {
          sTitle: 'Violet Prentiss',
          sAPBDB: 'Financial_E5'
        },
        {
          sTitle: 'Mirri Kent',
          sAPBDB: 'Financial_E6'
        },
        {
          sTitle: 'Akiko X',
          sAPBDB: 'Waterfront_E5'
        },
        {
          sTitle: 'Byeong Lee',
          sAPBDB: 'Waterfront_E7'
        }
      ],
      r3: [
        {
          sTitle: 'Ty Durrant',
          sAPBDB: 'Financial_E4'
        },
        {
          sTitle: 'Grissom',
          sAPBDB: 'Financial_E10'
        },
        {
          sTitle: 'Miguel Estebano',
          sAPBDB: 'Waterfront_E10'
        },
        {
          sTitle: 'Kaspar Danko',
          sAPBDB: 'Financial_E3'
        },
        {
          sTitle: 'Justin Teng',
          sAPBDB: 'Financial_E1'
        },
        {
          sTitle: 'Ernst \'Mule\' Templeton',
          sAPBDB: 'Waterfront_E11'
        }
      ],
      r4: [
        {
          sAPBDB: 'Praetorian'
        },
        {
          sTitle: 'Eva Orlandez',
          sAPBDB: 'Waterfront_E9'
        },
        {
          sTitle: 'Sabbat',
          sAPBDB: 'Waterfront_E1'
        },
        {
          sTitle: 'Saul Linklater',
          sAPBDB: 'Financial_E9'
        },
        {
          sTitle: 'Hea Choi',
          sAPBDB: 'Financial_E7'
        }
      ],
    }

    // Social District Contacts
    this.socialTree = [
      {
        sTitle: 'Anne',
        sAPBDB: 'Social_Armas_Legendaries'
      },
      {
        sTitle: 'Charlotte Bloodrose',
        sAPBDB: 'Social_2'
      },
      {
        sTitle: 'Darrell "Speedball" Kayne',
        sAPBDB: 'Social_Joker_Affiliate'
      },
      {
        sTitle: 'LaRocha',
        sAPBDB: 'Social_3'
      },
      {
        sTitle: 'Lawrence Holland',
        sAPBDB: 'Social_RIOT'
      },
      {
        sTitle: 'Ophelia',
        sAPBDB: 'Social_Armas_Unlocks'
      },
      {
        sTitle: 'Sofia',
        sAPBDB: 'Social_4'
      },
      {
        sTitle: 'Wilde',
        sAPBDB: 'Social_Armas'
      },
      {
        sTitle: 'Zombie',
        sAPBDB: 'Social_1'
      }
    ]

    // Event District Contacts
    this.eventTree = [
      {
        sTitle: 'Criminelf',
        sAPBDB: 'Seasonal_Christmas_Crim'
      },
      {
        sTitle: 'Elforcer',
        sAPBDB: 'Seasonal_Christmas_Enf'
      },
      {
        sTitle: 'RIOT Outsource Manager',
        sAPBDB: 'Financial_RIOT'
      },
      {
        sTitle: 'Treat',
        sAPBDB: 'Financial_Halloween_E1'
      },
      {
        sTitle: 'Trick',
        sAPBDB: 'Financial_Halloween_C1'
      },
      {
        sTitle: 'Toxin Agent',
        sAPBDB: 'Financial_Halloween_B1'
      }
    ]
  }

  isActiveOrganisation(org) {
    if (org.aRelatedContacts.length > 1 && !org.sAPBDB.includes('None') &&
        !org.sName.includes('default')) {

      return true;
    }
    return false;
  }

  render() {
    return (
      <SimpleBar style={{ height: 'calc(100vh - 56px)' }}
                className="contacts-component">
        <MetaDecorator title="Contacts" />
        <Image 
          alt="contacts background" 
          src={`${CDN_ROOT}/apbdb/background_detail.webp`}
          className="background-image opacity-5" />
        <Container>
          <Row>
            <ContactTree tree={this.enforcerTree} />
            <ContactTree tree={this.criminalTree} />
            <ContactOther 
              title="Social District Contacts"
              img="Joker"
              contacts={this.socialTree}
            />
            <ContactOther 
              title="Event Contacts"
              img="RIOT"
              contacts={this.eventTree}
            />
          </Row>
        </Container>
      </SimpleBar>
    )
  }
}


class ContactTree extends React.Component {
  render() {
    return (
      <Col md={12} className="contact-tree">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Tier 0</th>
              <th>Tier 1</th>
              <th>Tier 2</th>
              <th>Tier 3</th>
              <th>Tier 4</th>
              <th>Tier 5</th>
              <th>Specialist</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {this.props.tree.r1.map((c, i) => {
                if (i > this.props.tree.r1.length-4 ) {
                  return (
                    <td rowSpan={i > 6 ? 4 : 2} key={c.sAPBDB}>
                      <ContactColumn contact={c} />
                    </td>
                  )
                }
                return (
                  <td key={c.sAPBDB}>
                    <ContactColumn captionTop contact={c} />
                  </td>
                )
              })}
            </tr>
            <tr>
              {this.props.tree.r2.map((c, i) => {
                if (i === 0) {
                  return (
                    <td rowSpan={2} key={c.sAPBDB}>
                      <ContactColumn contact={c} />
                    </td>
                  )
                }
                return <td key={c.sAPBDB}><ContactColumn contact={c} /></td>
              })}
            </tr>
            <tr>
              {this.props.tree.r3.map((c, i) => {
                if (i > this.props.tree.r3.length-3 ) {
                  return (
                    <td rowSpan={2} key={c.sAPBDB}>
                      <ContactColumn contact={c} />
                    </td>
                  )
                }
                return (
                  <td key={c.sAPBDB}>
                    <ContactColumn captionTop contact={c} />
                  </td>
                )
              })}
            </tr>
            <tr>
              {this.props.tree.r4.map(c => (
                <td key={c.sAPBDB}>
                  <ContactColumn contact={c} />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </Col>
    )
  }
}

const ContactColumn = props => (
  <Figure className="contact-col">
    <NavLink to={(props.contact.sTitle ? '/contacts/' : '/organisations/') + props.contact.sAPBDB}>
    {!!props.captionTop && props.contact.sTitle &&
      <Figure.Caption>
        {props.contact.sTitle}
      </Figure.Caption>
    }
    <Figure.Image 
      className={props.contact.sTitle ? 'contact-img' : 'org-img'}
      alt={props.contact.sTitle || props.contact.sAPBDB}
      src={`${CDN_ROOT}/apbdb/contacts/${props.contact.sAPBDB}.webp`}
      onError={utils.handleImageError}
    />
    {!props.captionTop && props.contact.sTitle &&
      <Figure.Caption>
        {props.contact.sTitle}
      </Figure.Caption>
    }
    </NavLink>
  </Figure>
)

const ContactOther = props => (
  <Col as={Row} sm={12} md={6}>
    <Col md={12} className="text-center">
      <h4>{props.title}</h4>
    </Col>
    {props.contacts.map(c => (
      <Col key={c.sAPBDB} sm={12} md={6} xl={4} className="align-middle text-center">
        <ContactColumn contact={c} />
      </Col>
    ))}
  </Col>
)
